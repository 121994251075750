.hr {
  padding: 0;
  margin: 0;
  color: #cccccc;
}

.row {
  padding: 12px 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}

.col1 {}

.label1 {
  margin-bottom: 0;
}

.col2 {
  text-align: right;
  color: #cccccc
}

.focusedTitle {
  color: #DA1212;
}

.itemSelected {
  font-weight: bold; 
  color: #000000;
  background-color: #FFFFFF;
  border-width: 0;
  padding: 0
}

.itemUnselected {
  font-weight: normal;
  color: #c4c4c4;
  background-color: #FFFFFF;
  border-width: 0;
  padding: 0
}

.itemUnselectedFocused {
  font-weight: normal;
  color: #DA1212;
  background-color: #FFFFFF;
  border-width: 0;
  padding: 0;
}

.optPipe {
  margin: 0 8px;
}

.focusedOptPipe {
  margin: 0 8px;
  color: #DA1212;
}
