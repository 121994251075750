.navBar {
  border-bottom: 1px solid rgba(0000.1);
  background-color: #3B5DB5;
}

.drawerLink {
  padding:0.5rem 0 0 0;
  font-size: 0.9rem;
  text-decoration: none;
}
