.detailGuideRecommend {
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  margin-top: 12px;
}

.detailGuideTitle {
  margin-top: 12px;
  padding-top: 32px;
  padding-bottom: 32px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  color: #ffffff;
  background-color: #534D4D;
  text-align: center;
}

.detailGuideItemBg1 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: normal;
}

.detailGuideItemBg2 {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: normal;
  background-color: #FAFAFA;
}

.detailGuideItemLogo {
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
}

.detailGuideItemHeader {
  margin: 12px 0 0 0;
  padding: 0;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
  line-height: 16px;
}

.detailGuideItemContent {
  margin: 12px 0 0 0;
  padding: 0;
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  line-height: 16px;
}

.detailGuideWarning {
  margin: 12px 0 0 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  line-height: 16px;
  color: red;
}

