.reponsessDetailItemName {
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  padding-right: 0;
  margin-top: 12px;
}

.responsesDetailItemValue {
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  padding-left: 0;
  margin-top: 12px;
}

.responsesDetailItemAdditionalInfo {
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  color: #0740D3;
}