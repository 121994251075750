.responsesSummaryItemName {
  font-size: 13px;
  line-height: 28px;
  font-weight: normal;
  margin-top: 16px;
}

.responsesSummrayItemValue {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  margin-top: 0px;
}

.responsesSummaryItemAdditionalInfo {
  font-size: 10px;
  line-height: 12px;
  margin-top: 4px;
  color: #0740D3;
}
