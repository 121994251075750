.global-calTitle {
  border-bottom: 1px solid #A3A1A1;
  padding: 0.9rem 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: 'Work Sans';
  font-size: 22px;
  line-height: 26px;
  font-style: normal;
  font-weight: 700;
  align-items: center;
  letter-spacing: -0.02em;
  padding: 16px 0;
}

.global-requestForm {
  margin-top: 8px;
  margin-bottom: 0;
  padding: 0;
}

.global-requestButtonRow {
  margin-top: 16px;
}

.global-requestButton {
  background-color: #3B5DB5;
  color: #ffffff;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.02em;
}

