.responsesSummaryHr {
  padding: 0;
  margin: 8px 0 0 0;
  color: #cccccc;
}

.responsesSummaryButtonGroup {
  margin-top: 16px;
  font-size: 20px;
}

.responsesSummaryButtonGroup a {
  color: #212529;
  margin-right: 28px;
}
