.hr {
  padding: 0;
  margin: 0;
  color: #cccccc;
}

.row {
  padding: 12px 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}

.col1 {}

.col2 {
  text-align: right;
}

.label {
  margin: 0;
  line-height: 32px
}

.focusedTitle {
  color: #DA1212;
}

.input {
  border-right: 1;
  font-size: 14px;
  height: 32px;
}

.focusedInput {
  border-right-width: 1;
  border-color: #DA1212;
  color: #DA1212;
  font-size: 14px;
  height: 32px;
}

