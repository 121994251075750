.responsesDetailRecommend {
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  margin-top: 12px;
}

.responsesDetailHeader {
  margin-top: 12px;
  background-color: #FAFAFA;
  padding-top: 12px;
  padding-bottom: 8px;
}

.responsesDetailHeaderName1 {
  padding-right: 0;
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
}

.responsesDetailHeaderName2 {
  padding-left: 0;
}

.responsesDetailHeaderName2r1 {
  margin: 0;
}

.responsesDetailHeaderName2Content {
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  padding: 0;
}

.responsesDetailHeaderName2CAdditionalInfo {
  font-size: 10px;
  font-weight: normal;
  line-height: 16px;
  color: #0740D3;
  padding-top: 12px;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
}

.responsesDetailHeaderHr {
  padding: 0;
  margin: 0;
  color: #cccccc;
}
