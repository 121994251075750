.hr {
  padding: 0;
  margin: 0;
  color: #cccccc;
}

.row {
  padding: 12px 0;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
}

.col1 {}

.label1 {
  margin-bottom: 0;
}

.col2 {
  text-align: right;
  height: 32px;
}

.checkbox {
  font-size: 20px;
  line-height: 32px;
}
