.tableSection {
  margin: 12px 0 0 0;
  background-color: #FAFAFA;
}

.tableName {
  font-size: 14px;
  font-weight: bold;
  line-height: 28px;
}

.table {
  margin: 0;
}

.tableHeading {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  padding: 0 !important;
  text-align: right;
}

.tableHeading:first-child {
  text-align: right;
}

.tableBody {
  border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.tableData {
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 28px;
  border: 0;
  padding: 0 !important;
  text-align: right;
}

.tableData:first-child {
  text-align: right;
}
